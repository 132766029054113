import * as React from 'react';
import { useMixpanel } from 'gatsby-plugin-mixpanel';
import { Layout } from '../../components/Layout';
import { PricingSection } from '../../components/PricingSection';
import { SEO } from '../../components/SEO';
import { Box, Heading } from '@chakra-ui/react';
import { AnswerObjectionsSection } from '../../components/AnswerObjectionsSection';
import { QRScanSection } from '../../components/QRScanSection';
import { FinalCTA } from '../../components/FinalCTA';

const Pricing: React.FC = () => {
  const mixpanel = useMixpanel();
  React.useEffect(() => {
    mixpanel.track('Pricing page: Viewed Pricing Page');
  }, [mixpanel]);
  return (
    <Layout>
      <SEO
        title="Canyou Pricing | Simple affordable process automation for Australian Business"
        description="Simple and affordable pricing to help automate small - medium Australian Businesses"
      />
      <Heading as="h1" size="xl" bg="brand.egg" textAlign="center" pt={[3, 8]}>
        <Box as="span" color="brand.blue">
          Canyou.
        </Box>{' '}
        Pricing
      </Heading>
      <PricingSection />
      <AnswerObjectionsSection />
      <QRScanSection />
      <FinalCTA />
    </Layout>
  );
};

export default Pricing;
